@import '../../assets/styles/scss/variables';

.auth {
    height: 100vh;

    .auth__left__view {
        background-color: $primary-fill;
        background-image: url('../../assets/images/images/login.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .form-group {
        max-width: unset !important;
        margin-right: auto;
        margin-left: auto;
    }

    .allocation {
        display: block !important;
        right: 0 !important;
        left: unset !important;
        width: 200px;
        bottom: -40px !important;
    }

    .doughnut {
        width: 30px !important;
        margin-left: 20px;
    }

    .login {
        max-width: 620px;
    }
}
