@import './fonts.css';
@import './variables.css';

html {
    box-sizing: border-box;
    font-size: 56.25%;
    overflow-x: hidden;
}

@media (min-width: 1024px) {
    html {
        font-size: 62.5%;
    }
}

html,
body {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
}

body {
    position: relative;
    color: var(--text-fill);
    background-color: var(--white-fill);
    font: 500 1.2rem/1.5 'poppins-light', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

body::-webkit-scrollbar {
    display: none !important;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    border: 0;
    outline: 0;
    font: inherit;
    font-size: 100%;
    line-height: inherit;
    vertical-align: baseline;
    margin: 0;
}

a,
a:hover {
    text-decoration: none;
}

a {
    color: unset;
}

ul {
    padding: 0;
}
li {
    list-style: none;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-size: 100%;
    font-family: inherit;
    line-height: 1.15;
}

button,
input {
    overflow: visible;
}

.rounded {
    border-radius: 6px !important;
}

.bg-main {
    background-color: var(--fill-main);
}

.bg--primary {
    background-color: var(--primary-fill);
    color: var(--white-fill);
}

.bg--primary--light {
    background-color: var(--primary-fill-light);
}

.bg--danger--light {
    background-color: var(--danger-light);
}

.bg--gray--light {
    background-color: var(--gray-fill-light);
}

.bg-white {
    color: var(--text-fill) !important;
}

.text--primary {
    color: var(--primary-fill) !important;
}

.text--gray {
    color: var(--gray-fill) !important;
}

.text--danger {
    color: var(--danger-fill) !important;
}

.text--warning {
    color: var(--warning-fill) !important;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex__start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex__between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex__end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex__between__end {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.flex__start__vertical__center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex__column {
    display: flex;
    flex-direction: column;
}

.grid__center {
    display: grid;
    place-content: center;
}

.pointer {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:checked {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
}
.rel {
    position: relative;
}

.abs {
    position: absolute;
}

.sticky__right {
    position: sticky;
    position: -webkit-sticky;
    right: 0;
}

.h-50px {
    height: 50px;
}

.no__border {
    border: 0 !important;
}

@media (max-width: 800px) {
    .flex__between_tab {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .wrapper__col-12_mobile {
        width: 100%;
    }

    .flex-wrap-mobile {
        flex-wrap: wrap !important;
    }

    .flex__center_mobile {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .flex__between_mobile {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    }
}
