$primary-fill: #078950;
$primary-fill-med: #d1e38d;
$primary-fill-light: rgba(209, 227, 141, 0.5);
$text-fill-main: #071b2b;
$text-fill: #3e3e3e;
$text-info-fill: #505050;
$gray-fill-main: #828282;
$gray-fill-med: #aaaaaa;
$gray-fill-border: #e6e3e3;
$gray-fill-disabled: #d9d9d9;
$fill-main: #f9fafc;
$warning-fill: #ffa500;

$dark-fill: #151515;
$gray-fill-light: #f6f6f6;
$success-fill: #219653;
$danger-fill: #c81a2f;
$disabled-fill: #6fcf97;
$white-fill: #ffffff;
$blue-fill: #305ed2;
$purple-fill: #d230af;
$pink-fill: #fd8962;
$indigo-fill: #67748e;

$danger-light: rgba(207, 111, 111, 0.15);
$blue-light: rgba(48, 94, 210, 0.15);
$primary-light: rgba(90, 210, 48, 0.15);
$purple-light: rgba(210, 48, 175, 0.15);
$warning-light: rgba(255, 166, 0, 0.15);
$pink-light: rgba(254, 77, 76, 0.2);
$gray-light: rgba(217, 217, 217, 0.25);
$thick-gray: rgb(73, 88, 95);

$primary-grad: linear-gradient(227deg, #1fb170f7 -21.21%, #0e804d 53.06%, #077041 85.12%);
$primary-btn-grad: #6fcf9740;
$table-bg: #6fcf970d;
$acc-btn-bg: #f4faf7;

$sidebar-width: 250px;
