@import './variables';

.heading1 {
    font-style: normal;
    font-weight: 600;
    font-family: poppins-medium;
    font-size: 2rem;
    line-height: 40px;
    color: $text-fill-main;
}

.heading2 {
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0px;

    @media (max-width: 768px) {
        font-size: 2.5rem;
        line-height: 40px;
    }
}

.heading3,
.heading4 {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    color: $text-fill;
}

.heading5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    color: $text-fill;
}

.heading6 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    color: $text-fill;
}

.heading7 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    color: $text-fill;
}

.heading8 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    color: $text-fill;
}

.heading4 {
    font-family: poppins-medium;
}

.text--large {
    font-size: 22px;
}

.text--large-md {
    font-size: 20px;
}

.text--large-sm {
    font-size: 18px !important;
}

.text--large-xs {
    font-size: 16px !important;
}

.text--normal-lg {
    font-size: 14px !important;
}

.text--normal {
    font-size: 12px !important;
}

.text--normal-sm {
    font-size: 11px !important;
}

.text--poppins-bold {
    font-family: 'poppins-bold' !important;
}

.text--primary--med {
    color: $primary-fill-med;
}

.text--info {
    color: $text-info-fill !important;
}
