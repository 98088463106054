@import './variables';

svg {
    &.primary {
        path {
            fill: $primary-fill;
        }
    }
    &.danger {
        path {
            fill: $danger-fill;
        }
    }
    &.gray {
        path {
            fill: $gray-fill-main;
        }
    }
    &.gray-border {
        path {
            fill: $gray-fill-border;
        }
    }
    &.primary-light {
        path {
            fill: $primary-light;
        }
    }
    &.white {
        path {
            fill: $white-fill;
        }
    }
}

.nm-5 {
    margin-top: -25px;
}

.border-gray {
    border: 1px solid $gray-fill-border;
}

.rot-90 {
    transform: rotate(90deg);
}

.rot-180 {
    transform: rotate(180deg);
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.rot-270 {
    transform: rotate(-90deg);
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

/** BACKGROUNDS **/

.blue__light {
    background-color: $blue-light;
    color: $blue-fill;
}
.purple__light {
    background-color: $purple-light;
    color: $purple-fill;
}
.danger__light {
    background-color: $danger-light;
    color: $danger-fill;
}
.primary__light {
    background-color: $primary-btn-grad;
    color: $primary-fill;
}

.gray__light {
    background-color: $gray-fill-light;
    color: $gray-fill-main;
}

.warning__light {
    background-color: $warning-light;
    color: $warning-fill;
}

.bg__primary {
    background-color: $primary-fill;
}

.bg__primary_light {
    background-color: $primary-light;
}

.bg__danger_light {
    background-color: $danger-light;
}

.bg__yellow_light {
    background-color: $warning-light;
}

.bg__white {
    background-color: $white-fill;
}

.bg__gray {
    background-color: $gray-fill-light;
}
.primary__med {
    background-color: $primary-fill-med;
}
.green__light {
    background: $primary-btn-grad;
}
.faint__primary {
    background: $table-bg;
}

/** Borders **/

.border__bottom__gray__light {
    border-bottom: 2px solid $gray-light !important;
}

/** Circles **/

.med-circle {
    width: 30px !important;
    height: 30px !important;
}

/** Content Slider **/

.content__slider {
    overflow: scroll;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    flex-wrap: nowrap;
    box-sizing: border-box;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none !important;
    }
}

/** Dropdown **/

.MuiAccordionSummary-content {
    margin: 0 !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.MuiAccordionDetails-root {
    display: block !important;
    padding: 10px 0 0 0 !important;
}

.MuiAccordion-root {
    background: inherit !important;
    &.Mui-expanded {
        margin: 0 !important;
    }
}

.MuiAccordion-root:before {
    background: none !important;
}

.MuiPaper-elevation8 {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04) !important;
}

.port__summary {
    .switchers {
        display: flex;
        justify-content: flex-end;

        p {
            border-bottom: 1px solid #efefef;
            cursor: pointer;
            font-weight: 600;
            padding-right: 15px;
            padding-left: 15px;
            margin: 0;

            &.active {
                border-bottom: 4px solid #078950;
            }

            @media (max-width: 600px) {
                padding-right: 8px;
                padding-left: 8px;
            }
        }
    }
}

// Effects

.transition--ease {
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.transition-linear {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.spinner-green {
    border-color: $primary-fill;
    border-right-color: transparent;
}
