:root {
    --fill-main: #f9fafc;
    --primary-fill: #078950;
    --primary-fill-light: rgba(209, 227, 141, 0.15);
    --text-fill: #071b2b;
    --gray-fill: #828282;
    --gray-fill-light: #f6f6f6;
    --danger-fill: #c81a2f;
    --danger-light: rgba(200, 26, 47, 0.15);
    --white-fill: #ffffff;
    --warning-fill: #ffa500;
    --primary-grad: linear-gradient(
        227deg,
        rgba(31, 177, 112, 0.97) -21.21%,
        #0e804d 53.06%,
        #077041 85.12%
    );
}
