@import './variables';

// DEFAULT Accordion

.accordion-button {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    color: $text-fill;
}

.accordion-button::after {
    background-image: url('/assets/images/icons/chevron-down-primary.svg') !important;
    height: 14px !important;
    width: 14px !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url('/assets/images/icons/chevron-down-primary.svg') !important;
}

.accordion-button:focus {
    border-color: transparent;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    padding: 0.4rem 1.25rem 1rem 1.25rem !important;
}

//Custom Accordion

.custom-accordion-header {
    .accordion-button {
        padding: 1rem 1.25rem 0.4rem 1.25rem !important;
        width: 35px !important;
    }

    .accordion-button:focus {
        border-color: $white-fill !important;
    }

    .accordion-button:not(.collapsed) {
        color: $white-fill;
        background-color: $white-fill;
    }
}

.custom-accordion-item {
    border: 1px none transparent !important;
    background-color: $white-fill !important;
}

.custom-accordion-button {
    background-color: $white-fill !important;
    padding: 1rem 1.25rem 0.4rem 1.25rem !important;
    width: 35px !important;
}

// Customer Accordion

.customer-accordion-header {
    background-color: $table-bg !important;

    .accordion-button {
        background-color: $acc-btn-bg !important;
    }

    .accordion-button:not(.collapsed) {
        color: $text-fill !important;
    }

    .accordion-button::after {
        background-image: url('/assets/images/icons/chevron-down.svg') !important;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url('/assets/images/icons/chevron-down.svg') !important;
    }
}

.customer-accordion-body {
    background-color: $table-bg !important;
    padding: 0px !important;
}

// Profile Accordion

.profile-accordion-header {
    .accordion-button:focus {
        border-color: $white-fill !important;
    }

    .accordion-button::after {
        background-image: url('/assets/images/icons/chevron-down.svg') !important;
    }

    .accordion-button:not(.collapsed) {
        background-color: $white-fill;
        color: $text-fill;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url('/assets/images/icons/chevron-down.svg') !important;
    }
}

// Custom Accordion Table

.accordion-table-header {
    .accordion-body {
        padding: 0rem !important;
    }
    .accordion-table-row {
        padding: 0px !important;
    }

    .accordion-button {
        padding: 0.5rem 1rem 0rem 1rem !important;
        width: 10% !important;
    }

    .accordion-button:not(.collapsed) {
        background-color: $white-fill;
        color: $text-fill;
    }
}

.accordion-item {
    border: 0px;
}
