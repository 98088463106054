@import '../../assets/styles/scss/variables.scss';

.container {
    display: flex;
    --sidebar-width: 200px;
    --header-height: 60px;
    --small-sidebar-width: 40px;
}

.main {
    flex: 1;
    position: absolute;
    width: calc(100% - var(--small-sidebar-width));
    min-height: 100%;
    right: 0;
    overflow: sroll;
    transition: all 0.5s ease 0s;
    &.active {
        width: calc(100% - var(--small-sidebar-width));
    }
}

.content {
    min-height: calc(100vh - var(--header-height));
    padding: 10px 20px 40px 20px;
}

.sidebar,
.small-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-x: hidden;
    transition: all 0.5s ease 0s;
}

.sidebar {
    flex: 0 1 var(--sidebar-width);
    min-width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    margin-left: calc(-1 * var(--sidebar-width));
    z-index: 2;
    padding-top: 10px;

    &.active {
        margin-left: 0;
    }
}
.small-sidebar {
    padding-top: 12px;
    min-width: var(--small-sidebar-width);
    max-width: var(--small-sidebar-width);
    margin-left: 0;
    z-index: 3;
    &.active {
        margin-left: calc(-1 * var(--small-sidebar-width));
    }
}

.header {
    width: 100%;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .right__headers {
        svg {
            margin-left: 25px;
        }
    }
}

@media (min-width: 1024px) {
    .sidebar {
        margin-left: 0;
        &.active {
            margin-left: calc(-1 * var(--sidebar-width));
        }
    }

    .main {
        width: calc(100% - var(--sidebar-width));
        &.active {
            width: calc(100% - var(--small-sidebar-width));
        }
    }

    .small-sidebar {
        margin-left: calc(-1 * var(--small-sidebar-width));
        &.active {
            margin-left: 0;
        }
    }
}

@media (max-width: 600px) {
    .content {
        padding: 10px;
    }

    .header {
        padding: 10px;

        .right__headers {
            svg {
                margin-left: 10px;
            }
        }
    }
}
