@import '../../assets/styles/scss/variables';

.modal {
    .modal-dialog {
        min-height: 100vh;
        display: flex;
        align-items: center;
    }
    .modal-content {
        border: 0;
        border-radius: 10px;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
        padding: 25px 25px 20px 25px;
        overflow: hidden;
    }

    .primary__header {
        text-align: center;
        padding: 15px;
        background-color: $primary-fill;
        background-position: top right, bottom left;
        background-repeat: no-repeat;
        color: $white-fill;
        p {
            color: $white-fill;
        }
    }
}

@media (min-width: 992px) {
    .modal-md {
        max-width: 500px !important;
    }

    .modal-lg {
        max-width: 700px !important;
    }

    .modal-xl {
        max-width: 900px !important;
    }
}
