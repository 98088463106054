.loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    svg {
        margin-bottom: 10px;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        margin: 0.39em;
        background: #007db6;
        border-radius: 50%;
        animation: loading 1s infinite alternate;

        &:nth-of-type(2) {
            background: #008fb2;
            animation-delay: 0.2s;
        }
        &:nth-of-type(3) {
            background: #009b9e;
            animation-delay: 0.4s;
        }
        &:nth-of-type(4) {
            background: #00a77d;
            animation-delay: 0.6s;
        }
        &:nth-of-type(5) {
            background: #00b247;
            animation-delay: 0.8s;
        }
        &:nth-of-type(6) {
            background: #5ab027;
            animation-delay: 1s;
        }
        &:nth-of-type(7) {
            background: #078950;
            animation-delay: 1.2s;
        }
    }
    @keyframes loading {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
