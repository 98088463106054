@import './_variables';

.pagination {
    display: inline-block !important;
}

.pagination li {
    cursor: pointer;
    display: inline-block;
}

.pagination li a {
    display: block;
    padding: 8px 15px 7px;
    font-size: 12px;
    font-weight: 600;
    color: $text-fill;
    outline: none;
    text-decoration: none;
    border: 1px solid $gray-fill-main;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.pagination li:first-child a,
.pagination li:last-child a {
    color: $text-fill;
}

.pagination li:first-child a {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 0;
}

.pagination li:last-child a {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: 0;
}

.pagination li a:hover {
    color: $white-fill !important;
    background-color: $primary-fill;
    border-color: $primary-fill;
}

.pagination li.active a {
    color: $white-fill !important;
    background-color: $primary-fill;
    border-color: $primary-fill;
}
