@import '../../../../assets/styles/scss/variables.scss';

.sidebar {
    color: $gray-fill-main;
    font-size: 13px;
    font-weight: 600;
    background: $white-fill;
    overflow-y: auto;

    a {
        color: unset !important;
    }
}

button.sidebar__nav__item__link {
    background-color: inherit;
}

button.sidebar__nav__item__link:focus {
    outline: 0;
}

.sidebar__header {
    padding-bottom: 30px;
    text-align: center;
    cursor: pointer;
}

.sidebar__nav__item__link,
.sidebar__nav__dropdown__item a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.3rem 0.5rem 1.3rem 3rem;
    position: relative;
    line-height: 1.5;
    margin-bottom: 2rem;

    svg {
        width: 15px;
    }
}

.sidebar__nav__item__link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 4px;
    max-height: 0;
    height: 100%;
    transition: all 300ms linear;
}

.sidebar__nav__item__link:hover,
.sidebar__nav__item__link[data-active='true'] {
    color: $primary-fill !important;
    background: $primary-btn-grad;
    cursor: pointer;
    border-left: 5px solid $primary-fill;
    padding: 1.3rem 0.5rem 1.3rem 2.5rem;

    svg {
        path {
            fill: $primary-fill;
        }
    }
}

.small-sidebar {
    .sidebar__nav__item__link:hover,
    .sidebar__nav__item__link[data-active='true'] {
        border-left: unset;
    }
}

.sidebar__nav__item__link:hover {
    text-decoration: none;
}

.sidebar__nav__item__link:hover::before,
.sidebar__nav__item__link[data-active='true']::before {
    max-height: 100%;
}

.sidebar__nav__dropdown {
    margin-left: calc(2.5rem + 35px + 1rem);
}

.sidebar__nav__dropdown__item {
    margin-bottom: 0.3em;
}

.sidebar__nav__dropdown__item a {
    padding-left: 0;
    padding-right: 0;
}

.small-sidebar {
    // background: $primary;
    .sidebar__nav__item__link,
    .sidebar__nav__dropdown__item a {
        padding: 1.4rem 1rem;
        text-align: center;
    }

    .sidebar__nav__item__link::before {
        width: 0;
    }

    .sidebar__footer {
        width: 40px;
        padding-left: 1rem;
    }
    .sidebar__header {
        padding-bottom: 10px;
    }
}
