@import './variables';

// Section Wrappers
.div__wrapper {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    font-weight: 500;
    overflow: hidden;
    position: relative;
}

.wrapper__section {
    border-radius: 10px;
    box-shadow: 0px 15px 50px #a0a3bd1a;
    font-size: 12px !important;
    font-weight: 500;
}

.wrapper__section_dashed {
    border: 2px dashed $gray-fill-border;
    border-radius: 10px;
    font-size: 12px !important;
    font-weight: 500;
}

.wrapper__section_bordered {
    border: 2px solid $gray-fill-border;
    border-radius: 10px;
    font-size: 12px !important;
    font-weight: 500;
}

.wrapper__overflow {
    -ms-overflow-style: none;
    flex-wrap: nowrap;
    scrollbar-width: none;
    overflow-x: auto;
}

.wrapper__overflow::-webkit-scrollbar {
    display: none;
}

#wrapper__section-header {
    .form-group-select__menu {
        z-index: 3;
    }
}

// Component Wrappers

.wrapper__stats-icon {
    aspect-ratio: 53.03/46;
    border-radius: 8px;
}

.wrapper__stat {
    aspect-ratio: 255/140;
    width: 25%;

    @media (max-width: 900px) {
        width: 325px;
    }
}

.wrapper__stats-row {
    height: 140px;
}

.wrapper__pill {
    border-radius: 8px;
}

.wrapper__small_pill {
    border-radius: 4px;
    padding: 0.4rem 1rem !important;
    width: 150px;
}

.wrapper__top_users_pill {
    min-width: 90px;
}

.wrapper__file_pill {
    border: 1px solid $gray-fill-border;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    padding: 0.7rem 0.8rem !important;
    width: 180px;
}

.wrapper__file_section {
    border: 2px dashed $gray-fill-border;
    border-radius: 6px;
    font-size: 12px;
    padding: 1.5rem;

    &.pdf_viewer {
        display: flex;

        iframe {
            min-height: 80vh;
        }
    }
}

.wrapper__wallet_stat {
    aspect-ratio: 350/123;
    width: 33.33%;

    @media (max-width: 600px) {
        width: 450px;
    }
}

.wrapper__wallet_stats_wrapper {
    min-height: 150px;
}

.wrapper__customer_details {
    background: $table-bg;
    border-radius: 10px;
}

.wrapper__customer__details {
    height: 300px;
}

.wrapper__chart {
    border-radius: 13.24px;
    overflow: hidden;
}

// Width Wrappers

.wrapper__width_47_perc {
    width: 47%;
}

.wrapper__width_40_perc {
    width: 40%;
}

.wrapper__width_31_perc {
    width: 31%;
}

@media (max-width: 500px) {
    .wrapper__col-12_mobile {
        width: 100%;
    }

    .wrapper__col-10_mobile {
        max-width: 70%;
    }

    // Margin Wrappers

    .wrapper__mt-3-mobile {
        margin-top: 1rem;
    }

    .wrapper__mt-4-mobile {
        margin-top: 1.5rem;
    }

    .wrapper__mb-4-mobile {
        margin-bottom: 1.5rem;
    }

    .wrapper__me-0-mobile {
        margin-right: 0rem !important;
    }

    .wrapper__ms-0-mobile {
        margin-left: 0rem !important;
    }

    // Index Wrappers

    .wrapper__z-index-neg-25 {
        position: relative;
        z-index: -25;
    }
}
