@font-face {
    font-family: 'poppins-light';
    src: local('poppins'), url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'poppins-medium';
    src: local('poppins'), url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'poppins-bold';
    src: local('poppins'), url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'),
        url('../fonts/OpenSans/static/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
}
