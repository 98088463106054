@import './variables';

// Input Field
.form-group {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    min-height: 90px;
    justify-content: flex-start;

    &.zero-height {
        min-height: 0;
    }

    input {
        padding: 0.8rem 1rem;
        line-height: inherit;
        font-style: normal;
        color: inherit;
        border-radius: 8px;
        width: 100%;

        &.gray-input {
            background-color: $gray-fill-light;
        }

        &::placeholder {
            font: inherit;
            line-height: inherit;
            color: $gray-fill-med;
        }

        &:read-only {
            cursor: not-allowed;
        }

        &:disabled {
            background-color: $gray-fill-light;
            border: 1px solid $gray-fill-disabled;
        }
    }

    .form-group-label {
        margin-bottom: 0.5rem;
        font-family: 'poppins-light';
        color: $gray-fill-main;
        font-weight: 500;

        &.required {
            &::after {
                content: '*';
                color: $danger-fill;
                margin-left: 3px;
            }
        }

        .question {
            width: 15px;
            height: 15px;
            margin-left: 10px;
            border-radius: 50%;
            border: 1px solid $gray-fill-med;
            text-align: center;
            display: inline-block;
            font-size: 10px;
        }
    }

    .input-wrapper {
        border-radius: 8px;
        border: 1px solid $gray-fill-med;

        &.gray-input {
            border: 1px solid $gray-fill-light !important;
        }

        &.error {
            border: 1px solid $danger-fill;
        }
        &.disabled {
            border: none;
        }
    }

    &.small-form-group {
        input {
            padding: 0.4rem 0.8rem;
            font-size: 1.1rem;
            background: unset !important;
            &::-webkit-calendar-picker-indicator {
                margin-left: 0px !important;
            }
        }
    }

    .password-input-wrapper img {
        cursor: pointer;
    }

    .input-text-error {
        font-size: 0.9em;
        color: $danger-fill;
        font-family: poppins-medium;
    }
}

.new-report-input {
    .input-wrapper {
        height: 43px !important;
    }

    .form-group-select {
        [class*='-control'] {
            height: 43px !important;
        }
    }
}
.show__password svg path {
    fill: $text-fill-main !important;
}

.header__form {
    .form-group {
        min-height: 38px !important;
    }
}

// Checkbox
.custom-check-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    border-radius: 4px;

    input {
        height: 13px;
        width: 13px;
        padding: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        outline: none;
        transition-duration: 0.3s;
        cursor: pointer;
        border: 1px solid $dark-fill;

        &:checked {
            background-color: $dark-fill;
        }
    }

    span {
        font-weight: 400;
        margin-left: 10px;
        margin-top: 2px;
        font-size: 14px;
        color: $text-fill;
    }
}

// Select Field
.form-group-select,
.form-group-select_error,
.form-group-select--is-multi,
.form-group-select--is-multi_error {
    width: 100%;

    [class*='-control'] {
        margin-top: 0 !important;
        padding: 0 !important;
        border: 1px solid $gray-fill-med;
        border-radius: 8px !important;
        box-shadow: none !important;
        height: 15px !important;

        &:hover {
            border-color: $gray-fill-med !important;
        }
    }

    [class*='-option'] {
        background-color: $white-fill !important ;
        color: unset !important;
    }
    [class*='-option']:hover {
        background-color: $gray-fill-light !important ;
    }
    [class*='-container']:focus {
        border: none !important;
    }

    span {
        display: none;
    }

    .form-group-select__menu {
        z-index: 999 !important;
    }

    .form-group-select__indicator,
    .form-group-select--is-multi__indicator {
        padding: 0 10px !important;

        svg {
            height: 25px !important;
            width: 25px !important;

            path {
                fill: $gray-fill-med !important;
            }
        }
    }

    .form-group-select__control--is-disabled {
        background-color: $gray-fill-light;
        border: 1px solid $gray-fill-disabled !important;

        svg {
            height: 25px !important;
            width: 25px !important;

            path {
                fill: $gray-fill-disabled !important;
            }
        }
    }
}
.form-group-select_error,
.form-group-select--is-multi_error {
    [class*='-control'] {
        border: 1px solid $danger-fill !important;
    }
}

.form-group-select__dashed {
    border: 1px dashed $gray-fill-med;
    border-radius: 8px !important;

    .form-group-select {
        [class*='-control'] {
            border: 1px none !important;
        }
    }

    .form-group-select__control--is-disabled {
        background-color: $gray-fill-light;
        border: 1px solid $gray-fill-disabled;
    }
}

.form-group-select__dashed_error,
.form-group-select__dashed--is-multi_error {
    border: 1px dashed $danger-fill !important;
    border-radius: 5px;

    .form-group-select {
        [class*='-control'] {
            border: 1px none !important;
        }
    }
}

.form-group-select__gray {
    background-color: $gray-fill-light !important;
    border: 1px solid $gray-fill-light !important;
    border-radius: 8px !important;

    [class*='-option'] {
        background-color: $white-fill !important ;
        color: unset !important;
    }
    [class*='-option']:hover {
        background-color: $gray-fill-light !important ;
    }
    [class*='-container']:focus {
        border: none !important;
    }
}

//Otp Input
.otp__input {
    input {
        border: 1px solid $gray-fill-med !important;
        width: 40px !important;
        border-radius: 5px !important;
        margin-right: 10px;
        height: 5rem;
        font-family: poppins-medium;
        font-size: 2rem;
    }
}

//Date Field
.react-datepicker-wrapper {
    width: 100%;
}

.file__upload {
    font-size: 16px;
    margin-bottom: 15px;

    .icon__wrapper {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-right: 10px;
        border: 1px solid $gray-fill-border;
        border-radius: 50%;
    }

    .progress {
        background: rgba(111, 207, 151, 0.2);
        height: 0.5rem;
        margin: 3px 0;
        border-radius: 4px;
    }

    .form-group__drag-drop-wrapper {
        display: block;
        width: 100%;
        height: 120px;
        padding: 11px 16px;
        background: #f8f9fa;
        text-align: center;
        font-style: normal;
        font-size: 14px;
        border-radius: 4px;
        border: 1px dashed #ccc;
    }

    .form-group__drag-drop-wrapper[data-error='true'] {
        border-color: #dc3545 !important;
    }

    .form-group__drag-drop-wrapper.drag-active {
        border-style: dashed;
        border-width: 1px;
    }

    .form-group__drag-drop__label {
        font-weight: normal;
        font-size: 14px;
    }

    .form-group__drag-drop__label:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .file-field {
        display: none !important;
    }
}

.MuiOutlinedInput-input {
    padding: 6px 6px 7px 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.MuiOutlinedInput-notchedOutline {
    top: -2px !important;
    border: 1px solid $gray-fill-med !important;
}

.MuiFormControl-root {
    min-width: 100% !important;
    display: inline-block;
}

.MuiSelect-icon {
    top: unset !important;
}

.MuiInputBase-root {
    font-size: unset !important;
}

.MuiInputBase-input {
    height: unset !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $gray-fill-med !important;
    background-color: unset !important;
}

.MuiSelect-select {
    &:focus {
        background: unset !important;
    }
}

.borderless {
    .MuiOutlinedInput-input {
        padding: 0 !important;
        font-weight: 600;
        color: $text-fill;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
        background-color: unset !important;
    }

    .MuiSelect-icon {
        top: 1px !important;
    }

    .MuiSelect-select {
        &:focus {
            background-color: unset !important;
        }
    }
}

.small-date {
    width: 80px;

    @media (max-width: 600px) {
        width: 105px;
    }
}

.small_select {
    width: 100px;

    @media (max-width: 600px) {
        width: 84%;
    }
}

.medium_select {
    width: 130px;
}

.large_select {
    width: 190px;
}

.large_select_mobile {
    @media (max-width: 500px) {
        width: 100%;
    }
}

.no-margin {
    .form-group {
        font-size: 1em;
        margin: 0 !important;
    }
}

// * Telephone Input

.react-tel-input .form-control {
    width: 100%;
    border: none;
    background-color: none;
}

.react-tel-input .flag-dropdown {
    border: none;
    background-color: unset;
}

// Search Form

.search__form {
    background-color: $white-fill !important;
    position: relative;
    transition: all 500ms ease;
    width: 260px;

    .input-wrapper {
        border-radius: 5px !important;
    }

    .form-group {
        margin-bottom: 0;
        min-height: 38px !important;
    }

    .search__result {
        top: -15px;
        padding: 10px 15px;
        position: relative;
        border-radius: 6px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3) !important;
        background: $white-fill;
        z-index: 100000000000;
    }
}

.small__search__close {
    transition: all 1000ms ease;
    height: 32px;
    width: 32px !important;

    @media (max-width: 600px) {
        width: 30px !important;
    }
}

.small__search__open {
    transition: all 1000ms ease;
    height: 32px;
    width: 100%;

    svg {
        height: 13px;
        width: 13px;
    }
}

// Progress Bar

.progress {
    height: 5px;
}

.chart-progress {
    height: 3px !important;
}

.custom_select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 30px;

    .selected {
        border-radius: 8px;
        border: 1px solid $gray-fill-med;
        color: $thick-gray;
        cursor: pointer;
        user-select: none;
        height: 40px;
        display: flex;
        // justify-content: space-between;
        align-items: center;

        input {
            border: none !important;
            height: 100% !important;
        }

        &:hover {
            border: 1px solid $primary-fill;
        }

        &.open {
            border-radius: 8px 8px 0px 0px;

            svg {
                transform: rotate(180deg);
                transition: linear 0.3s;
            }
        }
    }
    .items {
        background: $white-fill;
        color: $dark-fill !important;
        border-radius: 0px 0px 6px 6px;
        overflow: hidden;
        border-right: 1px solid $gray-fill-med;
        border-left: 1px solid $gray-fill-med;
        border-bottom: 1px solid $gray-fill-med;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 99;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
        max-height: 230px;
        min-height: 180px;
        position: relative;
        padding-top: 5px;

        .option__list {
            color: $dark-fill;
            padding: 0rem 1em !important;
            cursor: pointer;
            user-select: none;
            font-size: 1.2rem;

            &:hover {
                background-color: $primary-fill;
                color: $white-fill;
            }
        }

        .options {
            max-height: 180px;
            overflow-y: scroll;
            padding-bottom: 50px;
        }

        .custom_select_footer {
            border-top: 1px solid $gray-fill-med;
            background: $white-fill;
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 0.4rem 2rem;
            cursor: pointer;
            color: $primary-fill;
            font-family: 'gilroy-bold';
            font-size: 1.4rem;
        }
    }
}
