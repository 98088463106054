@import './variables';

/** Navtabs **/

.nav-tabs {
    background: $gray-fill-light;
    border-bottom: none !important;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 5px;

    &.no-flex {
        justify-content: flex-start;
    }

    li.nav-item,
    a.nav-item {
        align-items: center;
        display: flex;
        font-size: 14px;
        justify-content: center;
        text-align: center;
    }

    .nav-link {
        color: #011e3f;
        font-weight: 700;
        margin-bottom: 0;
        padding: 10px 20px;
        border: none;
        transition: all 500ms linear;
        color: $gray-fill-main;
        white-space: nowrap;

        &.active {
            color: $text-fill;
            border-radius: 6px;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
        }
    }
}

.vol-chart-nav-tabs {
    background: $white-fill;
    border-bottom: none !important;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0px;

    li.nav-item,
    a.nav-item {
        align-items: center;
        display: flex;
        font-size: 14px;
        justify-content: center;
        text-align: center;
    }

    .nav-link {
        color: #011e3f;
        font-weight: 700;
        margin-bottom: 0;
        padding: 15px 20px;
        border: none;
        transition: none !important;
        color: $indigo-fill;
        white-space: nowrap;

        &.active {
            color: $text-fill;
            border-radius: 9.04px;
            border: 1px solid $primary-fill;
            box-shadow: none !important;
        }
    }
}

@media (max-width: 500px) {
    .nav-wide-mobile {
        width: 100%;
    }
}
