@import './variables';

.table-responsive {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none;
    }
}
.table__wrapper {
    display: flex;
    justify-content: space-between;
    max-height: 540px;
    width: 100%;

    &.full__height {
        max-height: 1000000px;
    }
    &.faint__bg {
        background: $table-bg;
        padding: 5px;
    }
    .thumb-horizontal {
        background-color: red;
        height: 10px !important;
    }

    .table {
        position: relative;
        color: $text-fill !important;
        margin-bottom: 0 !important;
        width: 100%;

        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
            border-color: $gray-light !important;
        }

        .table__head {
            background-color: $white-fill;
            font-weight: 900;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 2;

            th {
                color: $gray-fill-med;
                padding: 1rem 1.5rem !important;
                border-bottom: 0 !important;
            }

            th,
            td {
                vertical-align: middle !important;
            }
        }

        .table__body {
            td {
                font-weight: 500;
            }

            img {
                max-height: 25px;
            }
        }

        .table__foot {
            background-color: $white-fill;

            tr {
                border-color: transparent !important;
            }

            td {
                color: $gray-fill-med;
                font-weight: 700;
            }
        }

        .table__body,
        .table__head,
        .table__foot {
            td {
                padding: 1.5rem !important;
                border-top: 0 !important;
                font-size: 12px;
                vertical-align: middle;
            }
        }
    }

    .table__index {
        width: 30px;
        border: 1px solid #d9d9d940 !important;
        padding: 0 10px;
        font-weight: 600;
        padding-top: 10px;
    }
}

table > :not(:first-child) {
    border-top: 1px solid transparent !important;
}

.table__portfolio_table {
    max-height: 240px;
}

.table__short_height {
    max-height: 370px;
}
